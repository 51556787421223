import React from 'react';

import { List, Datagrid, TextInput, TextField, DateField, Pagination, ChipField } from 'react-admin';

const postFilters = [
    <TextInput label="Zoeken" source="customer_email" alwaysOn />,
	<TextInput label="Orderstatus" source="status" />,
];

const PostPagination = props => <Pagination rowsPerPageOptions={[100, 250, 500, 1000]} {...props} />;

export const OrdersList = (props) => {
	return (
		<List {...props} pagination={<PostPagination />} perPage={100} filters={postFilters} sort={{ field: 'created_at', order: 'DESC' }}>
			<Datagrid>
				<TextField source="id" />
				<TextField source="customer_firstname" />
				<TextField source="customer_email" />
				<TextField source="total_invoiced" />
				<ChipField source="status" />
				<DateField source="created_at" />
			</Datagrid>
		</List>
	
	);
};
