import React from 'react';

import { List, Datagrid, TextInput, TextField, Pagination } from 'react-admin';

const postFilters = [
    <TextInput label="Zoeken" source="notify_stock_quantity" alwaysOn />,
	<TextInput label="Orderstatus" source="is_salable" />,
];

const PostPagination = props => <Pagination rowsPerPageOptions={[100, 250, 500, 1000]} {...props} />;

export const OrderItems = (props) => {
	return (
		<List {...props} pagination={<PostPagination />} perPage={100} filters={postFilters} sort={{ field: 'low_stock_date', order: 'ASC' }}>
			<Datagrid>
				<TextField label="SKU" source="id" />
				<TextField label="Naam" source="value" />
                <TextField label="Voorraad" source="qty" />
				<TextField label="Datum minimale voorraad" source="low_stock_date" />
				<TextField label="Minimale voorraad" source="notify_stock_qty" />
				<TextField label="Op voorraad" source="is_in_stock" />
			</Datagrid>
		</List>
	
	);
};
