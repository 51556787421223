import React, { useState, useEffect } from 'react';
// admin imports
import { AdminContext, AdminUI, Resource, useDataProvider } from 'react-admin';

import buildHasuraProvider from 'ra-data-hasura';
// client import
import {
    ApolloClient,
    InMemoryCache
  } from "@apollo/client";
// components
import Dashboard from './components/Dashboard';
// pages
import LoginPage from './pages/login.js';
import { OrdersList } from './pages/orders';
import { OrderItems } from './pages/orderitems';

// utils
import authProvider from './utils/authProvider';
import { auth0 } from "./utils/authProvider";
// browser history
import { createBrowserHistory as createHistory } from 'history';

const history = createHistory();
const createApolloClient = async (token) => {
    return new ApolloClient({
        uri: 'https://definite-pelican-20.hasura.app/v1/graphql',
        cache: new InMemoryCache(),
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
    
}

// Example POST method implementation:
async function getTables(token) {
    // Default options are marked with *
    const response = await fetch('https://definite-pelican-20.hasura.app/api/rest/tables', {
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        headers: {
        'Authorization': `Bearer ${token}`
        },
    });

    let data = await response.json().then(d => 
        d.__schema.queryType.fields
    );

    return data;
}

const App = () => {
    const [dataProvider, setDataProvider] = useState({});
    const [tables, setTables] = useState({});
    
    useEffect(() => {
        const buildDataProvider = async () => {
            const isAuthenticated = await auth0.isAuthenticated();
            if(!isAuthenticated) {
                return;
            }
            const token = await auth0.getIdTokenClaims();
            const idToken = token.__raw;
            const apolloClient = await createApolloClient(idToken);
            const dataProvider = await buildHasuraProvider(
                { client: apolloClient }
            );

            let tabpromise = getTables(idToken);
            const tab = await tabpromise.then(t => t);

            setDataProvider(() => dataProvider);
            setTables(() => tab);
        }
        buildDataProvider();
    }, []);

    return (
        <AdminContext 
            dataProvider={dataProvider}
            authProvider={authProvider}
        >
            <AsyncResources tables={tables}
            />
        </AdminContext>
    )
};

const knownOptions = [
    { name: 'sales_order_scd', options: { label: 'Orders' }, list: OrdersList },
    { name: 'stock', options: { label: 'Voorraad' }, list: OrderItems },
];
function getOptions(name) {
    var result = knownOptions.find(obj => {
        return name.endsWith(obj.name);
    });

    if (result) {
        return result.options;
    }
}

function getList(name) {
    var result = knownOptions.find(obj => {
        return name.endsWith(obj.name);
    });

    if (result) {
        return result.list;
    }
}

function AsyncResources(props) {
    const [resources, setResources] = useState([]);
    const dataProvider = useDataProvider();

    const tables = props.tables

    useEffect(() => {
        if (tables.length > 0) {
            setResources(tables);
        }
        
    }, [dataProvider, tables]);

    return (
        <AdminUI
            title="Hasura Dashboard"
            dashboard={Dashboard}
            history={history}
            loginPage={LoginPage}
        >
            {resources.map(resource => (
                <Resource name={resource.name} key={resource.name} options={getOptions(resource.name)} list={getList(resource.name)} />
            ))}
            <Resource />
        </AdminUI>
    );
}

export default App;
